"use client"

import React from "react"

import { DMToastsContextProvider } from "../../hooks/useDMToast/DMToastsContext"
import { DMThemeProvider, DMTooltipProvider } from "../../index"

export type DMProviderProps = {
  children: React.ReactNode
  className?: string
}

export function DMProvider({ children, className }: DMProviderProps) {
  return (
    <DMThemeProvider
      className={className}
      theme="darkmatter"
      toastOptions={{
        bottom: { width: "492px" }, // 460px toast width + 2 x 16px padding of provider
      }}
    >
      <DMToastsContextProvider>
        <DMTooltipProvider>{children}</DMTooltipProvider>
      </DMToastsContextProvider>
    </DMThemeProvider>
  )
}
