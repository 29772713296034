export function verifyImage(url: string, timeout = 5000) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let timer: NodeJS.Timeout

    // eslint-disable-next-line no-multi-assign
    img.onerror = img.onabort = () => {
      clearTimeout(timer)
      reject(new Error("Image error"))
    }

    img.onload = () => {
      clearTimeout(timer)
      resolve(url)
    }

    timer = setTimeout(() => {
      reject(new Error("Image timeout"))
    }, timeout)
    img.src = url
  })
}
