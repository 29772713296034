"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as Progress from "@radix-ui/react-progress"

import { cva, type VariantProps } from "class-variance-authority"

const variants = cva("", {
  variants: {
    variant: {
      primary: "bg-primary",
      upgrade: "bg-upgrade",
    },
  },
})

const fillVariants = {
  primary: "bg-neutral-highlighted",
  upgrade: "bg-[#433466]", // TODO: This should probably be a DM color but it's not defined there
} as const

interface DMProgressBarProps {
  completed: number
  max?: number
  variant?: NonNullable<VariantProps<typeof variants>["variant"]>
}

const DMProgressBar = React.forwardRef<HTMLDivElement, DMProgressBarProps>(
  ({ completed, max = 100, variant = "primary" }, ref) => {
    return (
      <Progress.Root
        value={completed}
        max={max}
        ref={ref}
        style={{
          // Fix overflow clipping in Safari
          // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
          transform: "translateZ(0)",
        }}
        className={cn(
          "rounded w-full h-[6px] overflow-hidden",
          fillVariants[variant]
        )}
      >
        <Progress.Indicator
          style={{ width: `${(completed / max) * 100}%` }}
          className={cn(
            "h-full transition-all duration-700 ease-[cubic-bezier(0.65, 0, 0.35, 1)]",
            variants({ variant })
          )}
        />
      </Progress.Root>
    )
  }
)

DMProgressBar.displayName = "DMProgressBar"

export { DMProgressBar }
