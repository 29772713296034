"use client"

import React, { useId } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"

const RadioGroupErrorContext = React.createContext(false)

// Context hook for easier consumption
export function useRadioGroupError() {
  return React.useContext(RadioGroupErrorContext)
}

const DMRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    hasError?: boolean
  }
>(({ hasError = false, children, ...props }, ref) => (
  <RadioGroupErrorContext.Provider value={hasError}>
    <RadioGroupPrimitive.Root ref={ref} {...props} loop={false}>
      {children}
    </RadioGroupPrimitive.Root>
  </RadioGroupErrorContext.Provider>
))

DMRadioGroup.displayName = "DMRadioGroup"

const DMRadioGroupIndicator = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Indicator>
>(({ ...props }, ref) => <RadioGroupPrimitive.Indicator ref={ref} {...props} />)

DMRadioGroupIndicator.displayName = "DMRadioGroupIndicator"

const DMRadioGroupCircleIndicator = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Indicator>
>(({ ...props }, ref) => (
  <DMRadioGroupIndicator
    ref={ref}
    className={cn(
      "w-[6px] h-[6px] rounded-full",
      "data-[state=checked]:bg-white",
      "data-[disabled]:data-[state=checked]:bg-border-neutral"
    )}
    {...props}
  />
))

DMRadioGroupCircleIndicator.displayName = "DMRadioGroupIndicator"

const DMRadioGroupItemBase = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ children, ...props }, ref) => {
  const hasError = useRadioGroupError()
  const id = useId()

  return (
    <div className="flex gap-8 items-start text-body">
      <div className="p-2">
        <RadioGroupPrimitive.Item
          ref={ref}
          id={id}
          className={cn(
            "w-icon-small h-icon-small p-4 rounded-full cursor-pointer border border-neutral flex items-center justify-center focus:ring-2 focus:ring-offset-1",
            "data-[state=checked]:bg-primary data-[state=checked]:border-primary data-[state=checked]:hover:bg-border-primary data-[disabled]:data-[state=checked]:bg-disabled",
            "data-[disabled]:data-[state=unchecked]:hover:bg-disabled data-[disabled]:data-[state=checked]:hover:bg-disabled",
            "data-[disabled]:bg-disabled data-[disabled]:hover:bg-disabled data-[disabled]:border-disabled data-[disabled]:border-none",
            hasError &&
              "border-critical data-[state=checked]:border-critical data-[state=checked]:bg-critical data-[state=checked]:hover:bg-critical "
          )}
          {...props}
        >
          <DMRadioGroupCircleIndicator />
        </RadioGroupPrimitive.Item>
      </div>
      <label
        htmlFor={id}
        className={cn(
          props.disabled
            ? "cursor-not-allowed pointer-events-none text-disabled"
            : "cursor-pointer text-neutral"
        )}
      >
        {children}
      </label>
    </div>
  )
})

type RadioGroupItemProps = React.ComponentPropsWithoutRef<
  typeof DMRadioGroupItemBase
> & {
  label: string
  description?: string
}

function DMRadioGroupItem(props: RadioGroupItemProps) {
  const { label, description, ...rest } = props

  return (
    <DMRadioGroupItemBase {...rest}>
      <div className="flex flex-col flex-shrink text-body">
        <div>{label}</div>
        <div
          className={cn(
            props.disabled ? "text-disabled" : "text-neutral-faded"
          )}
        >
          {description}
        </div>
      </div>
    </DMRadioGroupItemBase>
  )
}

DMRadioGroupItemBase.displayName = "DMRadioGroupItem"

const DMRadioGroupPrimitiveItem = RadioGroupPrimitive.Item

export {
  DMRadioGroup,
  DMRadioGroupItem,
  DMRadioGroupItemBase,
  DMRadioGroupIndicator,
  DMRadioGroupCircleIndicator,
  DMRadioGroupPrimitiveItem,
}
