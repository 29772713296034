import React from "react"

import { cn } from "../../utils/cn"

import { Props } from "./DMIconWrapper.types"

function DMIconWrapper({ children, variant = "square" }: Props) {
  return variant === "square" ? (
    <div
      className={cn(
        "border-neutral-faded bg-elevation-base flex h-[40px] w-[40px] items-center justify-center rounded border"
      )}
    >
      {children}
    </div>
  ) : (
    <div className="border-neutral-faded rounded-full border border-dashed size-[176px] flex items-center justify-center">
      <div className="border-neutral-faded relative rounded-full border border-dashed size-[120px] flex items-center justify-center">
        <div className="bg-elevation-base border-neutral shadow-page-loader rounded-full border border-dashed size-[64px] flex items-center justify-center">
          <div className="size-[40px] rounded-full overflow-hidden flex items-center justify-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DMIconWrapper
