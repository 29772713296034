"use client"

import * as React from "react"
import { FieldPath, FieldValues, useFormContext } from "react-hook-form"

import {
  FormField,
  FormItem,
  FormLabel,
  DMFormErrorMessage,
  DMRadioGroup,
  DMRadioGroupItem,
  DMRadioGroupItemBase,
  FormControl,
} from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

type Props<T extends FieldValues> = {
  label?: string
  fieldName: FieldPath<T>
  hasOptionalBadge?: boolean
  infoTooltip?: string
  children:
    | React.ReactElement<typeof DMRadioGroupItem>[]
    | React.ReactElement<typeof DMRadioGroupItemBase>[]
  radioGroupClassName?: string
  defaultValue?: string
  onChange?: (value: string) => void
  value?: string
}

function DMFormRadioGroup<T extends FieldValues>(props: Props<T>) {
  const {
    label,
    fieldName,
    hasOptionalBadge,
    infoTooltip,
    children,
    radioGroupClassName,
    defaultValue,
    onChange,
    value,
  } = props

  const {
    formState: { errors },
    control,
  } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="flex flex-col gap-8">
          {label && (
            <FormLabel
              hasOptionalBadge={hasOptionalBadge}
              infoTooltip={infoTooltip}
              text={label}
            />
          )}
          <FormControl>
            <DMRadioGroup
              value={value}
              defaultValue={defaultValue}
              hasError={!!errors[fieldName]}
              className={cn("flex flex-col gap-8", radioGroupClassName)}
              onValueChange={(value: string) => {
                field.onChange(value)
                onChange?.(value)
              }}
            >
              {children}
            </DMRadioGroup>
          </FormControl>

          <DMFormErrorMessage size="medium" />
        </FormItem>
      )}
    />
  )
}

export { DMFormRadioGroup }
