import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import "./styles.css"

export type DMPageLoaderProps = {
  title?: string | null
  className?: string
}

export function DMPageLoader(props: DMPageLoaderProps) {
  const { title, className } = props

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-16",
        className
      )}
      data-test-id="page_loader"
    >
      <div className="flex items-center justify-center">
        <div className="opacity-40">
          <div className="h-[248px] w-[248px] border-neutral duration-spin animate-pulse rounded-full border border-dashed" />
        </div>
        <div className="absolute opacity-70">
          <div className="h-[184px] w-[184px] border-neutral duration-spin animate-pulse rounded-full border border-dashed delay-100" />
        </div>
        <div className="absolute flex justify-center items-center duration-spin animate-pulse delay-200">
          <div className="left-[-88px] top-[49%] h-[1px] w-[120px] bg-gradient-to-l from-[var(--rs-color-border-neutral)]" />
          <div className="h-[120px] w-[120px] border-neutral relative rounded-full border border-dashed" />
          <div className="right-[-88px] top-[49%] h-[1px] w-[120px] bg-gradient-to-r from-[var(--rs-color-border-neutral)]" />
        </div>
        <div className="absolute page-loader-logo-animation flex justify-center items-center">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="var(--rs-color-foreground-neutral)"
              strokeWidth="3"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M30 33.9579C32.1859 33.9579 33.9579 32.1859 33.9579 30C33.9579 27.814 32.1859 26.042 30 26.042C27.814 26.042 26.042 27.814 26.042 30C26.042 32.1859 27.814 33.9579 30 33.9579Z" />

              <path
                className="path1"
                d="M26.042 30C26.042 26.5224 30.6331 23.7053 36.2947 23.7053C41.9581 23.7053 46.5473 26.5243 46.5473 30C46.5473 33.4777 41.9562 36.2947 36.2947 36.2947"
              />
              <path
                className="path2"
                d="M30 33.958C26.5224 33.958 23.7053 29.3668 23.7053 23.7053C23.7053 18.0418 26.5243 13.4526 30 13.4526C33.4777 13.4526 36.2947 18.0438 36.2947 23.7053"
              />
              <path
                className="path3"
                d="M33.958 30C33.958 33.4777 29.3668 36.2947 23.7053 36.2947C18.0418 36.2947 13.4526 33.4757 13.4526 30C13.4526 26.5224 18.0438 23.7053 23.7053 23.7053"
              />
              <path
                className="path4"
                d="M30 26.042C33.4777 26.042 36.2947 30.6331 36.2947 36.2947C36.2947 41.9581 33.4757 46.5473 30 46.5473C26.5224 46.5473 23.7053 41.9562 23.7053 36.2947"
              />

              <path
                className="path5"
                d="M26.042 30C26.042 26.5224 30.6331 23.7053 36.2947 23.7053C41.9581 23.7053 46.5473 26.5243 46.5473 30C46.5473 33.4777 41.9562 36.2947 36.2947 36.2947"
              />
              <path
                className="path6"
                d="M30 26.042C33.4777 26.042 36.2947 30.6331 36.2947 36.2947C36.2947 41.9581 33.4757 46.5473 30 46.5473C26.5224 46.5473 23.7053 41.9562 23.7053 36.2947"
              />
              <path
                className="path7"
                d="M33.958 30C33.958 33.4777 29.3668 36.2947 23.7053 36.2947C18.0418 36.2947 13.4526 33.4757 13.4526 30C13.4526 26.5224 18.0438 23.7053 23.7053 23.7053"
              />
              <path
                className="path8"
                d="M30 33.958C26.5224 33.958 23.7053 29.3668 23.7053 23.7053C23.7053 18.0418 26.5243 13.4526 30 13.4526C33.4777 13.4526 36.2947 18.0438 36.2947 23.7053"
              />
            </g>
          </svg>
        </div>
      </div>
      {title && <div className="text-body text-neutral-faded">{title}</div>}
    </div>
  )
}
