import React from "react"

import { DMIcon } from "@supernovaio/dm"

import type { IconType } from "@supernovaio/icons/types"

import { DMNavItemBase, DMNavItemBaseProps } from "../DMNavItemBase"

interface DMNavSectionProps
  extends Pick<DMNavItemBaseProps, "size" | "startSlot" | "endSlot"> {
  icon?: IconType
  text: string
}

function DMNavSection({
  icon,
  startSlot,
  text,
  endSlot,
  size = "small",
}: DMNavSectionProps) {
  return (
    <div className="rounded">
      <DMNavItemBase
        endSlot={endSlot}
        iconSlot={
          icon ? <DMIcon color="neutral-faded" size={size} svg={icon} /> : null
        }
        size={size}
        startSlot={startSlot}
        textSlot={
          <div className="text-body text-neutral-faded neutral-faded line-clamp-1 min-w-[96px] flex-grow font-semibold">
            {text}
          </div>
        }
      />
    </div>
  )
}

export default DMNavSection
