"use client"

import React, { useCallback, useState } from "react"

import { DMLabel } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SwitchPrimitives from "@radix-ui/react-switch"

import { DMSwitch } from "../DMSwitch/DMSwitch"
import { getMixedLabel, MixedLabel, SwitchProps } from "../types"

function getCommonClassNames(isLabelRight?: boolean) {
  return cn("flex grow-0 items-center gap-8", {
    "flex-row-reverse": !isLabelRight,
  })
}

type MixedSwitchProps = Omit<SwitchProps, "isChecked"> & {
  label?: string
  onClick?: () => void
}

function MixedSwitch(props: MixedSwitchProps) {
  const { isLabelRight, id, label, onChange, onClick, size, isDisabled } = props

  return (
    <div className={getCommonClassNames(isLabelRight)}>
      <SwitchPrimitives.Root
        className={cn(
          "bg-primary peer grid cursor-pointer place-items-center rounded-[999px] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:cursor-not-allowed",
          "data-[disabled]:data-[state=checked]:bg-disabled",
          "data-[disabled]:data-[state=checked]:hover:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:hover:bg-disabled",
          {
            "h-[20px] w-[32px]": size === "medium",
            "h-[16px] w-[28px]": size === "small",
          }
        )}
        disabled={isDisabled}
        id={id}
        onCheckedChange={onChange}
        onClick={onClick}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            "flex rounded-[999px] bg-white transition-transform",
            "data-[disabled]:shadow-raised",
            {
              "h-[3px] w-[12px]": size === "medium",
              "h-[2.4px] w-[9.6px]": size === "small",
            }
          )}
        />
      </SwitchPrimitives.Root>
      {label && (
        <DMLabel
          className="text-body-small peer-disabled:text-disabled font-semibold peer-disabled:cursor-not-allowed"
          htmlFor={id}
        >
          {label}
        </DMLabel>
      )}
    </div>
  )
}

type DMSwitchMixedProps = Omit<SwitchProps, "isChecked"> & {
  label?: MixedLabel
  isChecked?: SwitchProps["isChecked"] | "mixed"
}

function DMSwitchMixed(props: DMSwitchMixedProps) {
  const {
    isChecked,
    isLabelRight = false,
    label,
    onChange,
    id,
    isDisabled,
    size = "small",
  } = props

  const [isMixed, setIsMixed] = useState(isChecked)

  const ref = useCallback((node: HTMLButtonElement | null) => {
    node?.focus()
  }, [])

  const handleMixedClick = () => {
    if (isChecked === "mixed") setIsMixed(true)
  }

  if (isMixed === "mixed") {
    return (
      <MixedSwitch
        id={id}
        isDisabled={isDisabled}
        isLabelRight={isLabelRight}
        label={getMixedLabel(label)}
        size={size}
        onChange={onChange}
        onClick={handleMixedClick}
      />
    )
  }

  return (
    <DMSwitch
      ref={ref}
      id={id}
      isChecked={isMixed}
      isDisabled={isDisabled}
      isLabelRight={isLabelRight}
      label={label}
      size={size}
      onChange={onChange}
    />
  )
}

export { DMSwitchMixed }
