import React from "react"

import { DMIcon } from "@supernovaio/dm/src/components/DMIcon"
import { DMImage } from "@supernovaio/dm/src/components/DMImage"
import {
  DMRadioGroupCircleIndicator,
  DMRadioGroupPrimitiveItem,
} from "@supernovaio/dm/src/components/DMRadioGroup"
import { cn } from "@supernovaio/dm/src/utils/cn"

import gradient from "@supernovaio/assets/illustrations/dm_onboarding_tile_gradient.svg"
import { IconType } from "@supernovaio/icons/types"

type Props = {
  text?: React.ReactNode
  description?: React.ReactNode
  value: string
  icon?: IconType
}

export function DMRadioTile(props: Props) {
  const { value, icon, text, description } = props

  return (
    <DMRadioGroupPrimitiveItem
      value={value}
      className={cn(
        "group",
        "flex flex-col",
        "rounded inner-border inner-border-neutral-faded",
        "min-w-[188px] min-h-[136px]",
        "bg-elevation-base",
        "hover:inner-border-primary-faded hover:bg-primary-faded",
        "data-[state=checked]:inner-border-primary data-[state=checked]:bg-primary-faded",
        "focus:outline-offset-1"
      )}
    >
      <div className="relative w-full h-full">
        <DMImage
          image={gradient}
          className="absolute w-auto h-auto top-0 right-0"
        />

        <div className="w-full h-full p-20">
          <div className="flex flex-col gap-8 pl-4 pt-4">
            <div className="flex flex-row justify-between">
              {icon && <DMIcon svg={icon} color="neutral-faded" size="large" />}
              <div
                className={cn(
                  "w-icon-small h-icon-small p-4 rounded-full cursor-pointer border border-neutral flex items-center justify-center group-focus:ring-2 group-focus:ring-offset-1",
                  "group-data-[state=checked]:bg-primary group-data-[state=checked]:border-primary group-data-[state=unchecked]:bg-primary-faded group-data-[state=unchecked]:group-hover:bg-primary-faded group-data-[disabled]:data-[state=checked]:bg-disabled",
                  "group-data-[disabled]:data-[state=unchecked]:hover:bg-disabled group-data-[disabled]:data-[state=checked]:hover:bg-disabled",
                  "group-data-[disabled]:bg-disabled group-data-[disabled]:hover:bg-disabled group-data-[disabled]:border-disabled group-data-[disabled]:border-none"
                )}
              >
                <DMRadioGroupCircleIndicator />
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start h-full max-w-[202px]">
              <div className="text-body-large font-bold truncate text-start w-full">
                {text}
              </div>
              <div className="text-body text-neutral-faded text-ellipsis text-start h-full">
                {description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DMRadioGroupPrimitiveItem>
  )
}
