"use client"

import * as React from "react"
import { ReactElement, ReactNode, useContext, useState } from "react"

import { IconCaret, IconDeselectAll, IconSelectAll } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import {
  ComboboxItemProps as ComboboxItemPropsPrimitive,
  ComboboxItem as ComboboxItemPrimitive,
  useComboboxContext,
} from "@ariakit/react"

import { cn } from "../../../utils/cn"
import { highlightSearchText } from "../../../utils/highlightText"
import { DMButtonPrimary } from "../../DMButtons"
import { DMIcon } from "../../DMIcon"
import { DMTooltip } from "../../DMTooltip"
import { ListboxCheckmarkIcon } from "../utils"

import { DMListboxTreeContext } from "./DMListboxTree"

export interface DMListboxTreeItemProps
  extends Omit<
    ComboboxItemPropsPrimitive,
    "disabled" | "focusable" | "tabbable" | "focusOnHover"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  isTabbable?: boolean
  isDefaultExpanded?: boolean
  isSelectable?: boolean
  isExpanded?: boolean
  isExpandable?: boolean
  title: string
  description?: string
  icon?: IconType | ReactElement
  endSlot?: ReactElement | string | undefined
  level?: number
  children?: ReactNode
  hideCheckbox?: boolean
  onBulkSelection?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  onExpand?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const DMListboxTreeItem = React.forwardRef<
  React.ElementRef<typeof ComboboxItemPrimitive>,
  DMListboxTreeItemProps
>(
  (
    {
      value,
      title,
      level = 0,
      icon,
      endSlot,
      isDisabled,
      isFocusable,
      isSelectable = true,
      isExpandable,
      isTabbable,
      isDefaultExpanded = false,
      children,
      hideCheckbox,
      onBulkSelection,
      ...props
    },
    ref
  ) => {
    const combobox = useComboboxContext()
    const { allItems, highlightSearch } = useContext(DMListboxTreeContext)
    const isMultiselect = combobox?.useState((state) =>
      Array.isArray(state.selectedValue)
    )
    const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)

    const search = combobox?.useState((state) => state.value)

    const isSelected = combobox?.useState((state) => {
      if (!value) return false

      return isMultiselect
        ? state.selectedValue.includes(value)
        : state.selectedValue === value
    })

    const isIndeterminate = (value: string | undefined) => {
      if (!value) return false
      return allItems.indeterminateItems.includes(value)
    }

    const hasAllChildrenSelected = (value: string | undefined) => {
      if (!value) return false
      return allItems.allChildrenSelectedItems.includes(value)
    }

    const shouldBeInteractedWith = search === "" || !children

    return (
      <li>
        <ComboboxItemPrimitive
          ref={ref}
          focusOnHover
          disabled={isDisabled}
          focusable={isFocusable}
          hideOnClick={false}
          setValueOnClick={false}
          selectValueOnClick={isSelectable} // if children, the item will not get selected on click
          onClick={() => {
            if (!isSelectable) {
              setIsExpanded(!isExpanded)
            }
          }}
          tabbable={isTabbable}
          onKeyDown={(e) => {
            if (e.key === "ArrowRight") {
              setIsExpanded(true)
            }
            if (e.key === "ArrowLeft") {
              setIsExpanded(false)
            }
            if (onBulkSelection && e.key === "Enter" && e.shiftKey) {
              e.preventDefault()
              onBulkSelection()
            }
          }}
          value={value}
          {...props}
          className={cn("group flex")}
        >
          <div
            className={cn(
              "item-content w-full",
              "text-body text-neutral font-semibold",
              "flex flex-row justify-between items-center px-8 py-[6px] mb-nav-items rounded",
              isSelected &&
                "!bg-primary-faded group-data-[active-item]:inner-border inner-border-primary-faded",
              "group-data-[active-item]:bg-neutral",
              isDisabled && "opacity-disabled"
            )}
          >
            <div className="flex gap-8 items-center">
              {icon && <DMIcon svg={icon} />}
              <div className="flex gap-4 items-center">
                <div>
                  {highlightSearch
                    ? highlightSearchText(title, search ?? "")
                    : title}
                </div>
                {isExpandable && search === "" && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsExpanded(!isExpanded)
                    }}
                    type="button"
                  >
                    <DMIcon
                      svg={IconCaret}
                      className={cn(
                        "transition-transform",
                        isExpanded && "rotate-90"
                      )}
                    />
                  </button>
                )}
              </div>
            </div>
            {!hideCheckbox && shouldBeInteractedWith && (
              <div className="flex gap-8 items-center">
                <div className="flex gap-4">
                  {onBulkSelection && isMultiselect && (
                    <div className="h-[16px] items-center hidden group-data-[active-item]:flex">
                      <DMTooltip
                        text={
                          hasAllChildrenSelected(value)
                            ? "Deselect all children"
                            : "Select all children"
                        }
                      >
                        <DMButtonPrimary
                          size="small"
                          icon={
                            hasAllChildrenSelected(value)
                              ? IconDeselectAll
                              : IconSelectAll
                          }
                          variant="ghost"
                          onClick={(e) => {
                            if (
                              typeof onBulkSelection === "function" ||
                              !isSelectable
                            ) {
                              e.stopPropagation()
                            }
                            onBulkSelection()
                          }}
                        />
                      </DMTooltip>
                    </div>
                  )}

                  {endSlot && (
                    <div className="text-neutral-faded text-body-small font-semibold">
                      {endSlot}
                    </div>
                  )}
                </div>

                <ListboxCheckmarkIcon
                  isSelected={isSelected}
                  isMultiselect={isMultiselect}
                  hasAllChildrenSelected={hasAllChildrenSelected(value)}
                  isIndeterminate={isIndeterminate(value)}
                  isSelectable={isSelectable}
                />
              </div>
            )}
          </div>
        </ComboboxItemPrimitive>
        {children && (isExpanded || search !== "") && (
          <ul
            className={cn(
              "ml-20 relative",
              "before:border-l before:border-neutral-faded before:border-dashed before:absolute before:top-0 before:-left-4 before:bottom-4"
            )}
          >
            {children}
          </ul>
        )}
      </li>
    )
  }
)

DMListboxTreeItem.displayName = "DMListboxTreeItem"

export { DMListboxTreeItem }
