import React from "react"

import { DMIcon } from "@supernovaio/dm/src/components/DMIcon"
import { DMImage } from "@supernovaio/dm/src/components/DMImage"
import {
  DMRadioGroupIndicator,
  DMRadioGroupPrimitiveItem,
} from "@supernovaio/dm/src/components/DMRadioGroup"
import { cn } from "@supernovaio/dm/src/utils/cn"

import gradient from "@supernovaio/assets/illustrations/dm_onboarding_tile_pill_gradient.svg"
import { IconCheckCircleFilled } from "@supernovaio/icons"

import {
  DMTwoDashedCircleProps,
  DMTwoDashedCircles,
} from "../DMTwoDashedCircles"

type Props = Omit<DMTwoDashedCircleProps, "size"> & {
  text?: React.ReactNode
  value: string
}

export function DMRadioPill(props: Props) {
  const { value, icon, text, iconColor = "neutral-faded", isDisabled } = props
  return (
    <DMRadioGroupPrimitiveItem
      value={value}
      disabled={isDisabled}
      className="group w-full relative outline-none"
    >
      <DMImage image={gradient} className="absolute inset-0" />
      <div
        className={cn(
          "flex flex-col items-center",
          "rounded inner-border inner-border-neutral-faded",
          "w-full h-[144px] p-12",
          "bg-elevation-base transition-all",
          "group-data-[state=checked]:inner-border-primary group-data-[state=checked]:bg-primary-faded",
          "outline-none ring-offset-1 group-focus-visible:ring-2",
          {
            "cursor-not-allowed text-disabled": isDisabled,
            "group-hover:inner-border-primary-faded group-hover:bg-primary-faded":
              !isDisabled,
          }
        )}
      >
        <div className="flex flex-col h-full justify-between items-center">
          <DMTwoDashedCircles
            icon={icon}
            iconColor={isDisabled ? "disabled" : iconColor}
            isDisabled={isDisabled}
          />
          <div className="flex gap-4 items-center justify-start">
            <div className="text-body truncate">{text}</div>
            <DMRadioGroupIndicator>
              <DMIcon svg={IconCheckCircleFilled} color="primary" />
            </DMRadioGroupIndicator>
          </div>
        </div>
      </div>
    </DMRadioGroupPrimitiveItem>
  )
}
