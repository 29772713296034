"use client"

import * as React from "react"
import { FieldPath, FieldValues, useFormContext } from "react-hook-form"

import {
  DMField,
  DMFormErrorMessage,
  DMNumberInput,
  DMNumberInputProps,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@supernovaio/dm"

type Props<T extends FieldValues> = Omit<
  DMNumberInputProps,
  "name" | "value" | "onChange" | "hasError" | "onValueChange"
> & {
  label: string
  name: FieldPath<T>
}

function DMFormNumberField<T extends FieldValues>(props: Props<T>) {
  const { label, name, ...restProps } = props

  const {
    formState: { errors },
    control,
  } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, name, ref, value } }) => (
        <FormItem>
          <DMField>
            <FormLabel text={label} />
            <FormControl>
              <DMNumberInput
                hasError={!!errors[name]}
                {...restProps}
                ref={ref}
                name={name}
                value={value}
                onValueChange={onChange}
              />
            </FormControl>
            <DMFormErrorMessage size="medium" />
          </DMField>
        </FormItem>
      )}
    />
  )
}

export { DMFormNumberField }
