import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMSpacing } from "../../types/spacing"

type ExampleItemProps = {
  title?: string | string[]
  children?: React.ReactNode
  gap?: DMSpacing
  className?: string
}

function Example(props: {
  children: React.ReactNode
  title?: React.ReactNode
}) {
  return (
    <div className="mb-24 flex w-full flex-col gap-24">
      {props.title && (
        <div className="border-neutral-faded bg-blur-neutral-faded text-title-4 sticky top-0 z-10 -ml-32 -mr-32 border-y px-40 py-24">
          {props.title}
        </div>
      )}
      {props.children}
    </div>
  )
}

function ExampleItem(props: ExampleItemProps) {
  const { children, gap, className } = props
  const title = typeof props.title === "string" ? [props.title] : props.title

  return (
    <div className="bg-elevation-base border-neutral-faded rounded border">
      <div className="bg-neutral-faded border-neutral-faded border-b p-16">
        {title &&
          title.map((line, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={cn(
                "text-body-small",
                index > 0 ? "text-neutral-faded" : "text-neutral"
              )}
            >
              {line}
            </div>
          ))}
      </div>
      <div
        className={cn(
          "bg-dotted w-full p-16",
          gap && `gap-${gap} flex flex-col`,
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

Example.Item = ExampleItem
export default Example
