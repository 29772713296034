import * as React from "react"
import { SVGProps } from "react"

export const IconCircleDashed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M5.707 2.46a6 6 0 0 0-1.947 1.3m-1.3 1.947A6 6 0 0 0 2 8m.46 2.293a6 6 0 0 0 1.3 1.947m1.947 1.3A6 6 0 0 0 8 14m2.293-.46a6 6 0 0 0 1.947-1.3m1.3-1.947A6 6 0 0 0 14 8m-.46-2.293a6 6 0 0 0-1.3-1.947m-1.947-1.3A6 6 0 0 0 8 2"
    />
  </svg>
)
