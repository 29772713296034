import React from "react"

import { DMIcon } from "@supernovaio/dm/src/components/DMIcon"
import { DMImage } from "@supernovaio/dm/src/components/DMImage"
import {
  DMRadioGroupIndicator,
  DMRadioGroupPrimitiveItem,
} from "@supernovaio/dm/src/components/DMRadioGroup"
import { cn } from "@supernovaio/dm/src/utils/cn"

import gradient from "@supernovaio/assets/illustrations/dm_onboarding_tile_pill_gradient.svg"
import { IconCheckCircleFilled } from "@supernovaio/icons"

import {
  DMTwoDashedCircleProps,
  DMTwoDashedCircles,
} from "../DMTwoDashedCircles"

type Props = Omit<DMTwoDashedCircleProps, "size" | "isDisabled"> & {
  text?: React.ReactNode
  description?: React.ReactNode
  value: string
  // TODO: Add disabled state
}

export function DMRadioRow(props: Props) {
  const { value, icon, text, description, iconColor = "neutral-faded" } = props
  return (
    <DMRadioGroupPrimitiveItem
      value={value}
      className={cn(
        "group",
        "relative outline-none",
        "w-full h-[52px] flex flex-row transition-all",
        "bg-elevation-base rounded border border-neutral-faded",
        "hover:border-primary-faded hover:bg-primary-faded",
        "data-[state=checked]:border-primary-faded data-[state=checked]:bg-primary-faded",
        "ring-offset-1 focus-visible:ring-2"
      )}
    >
      <div className="rounded h-full w-full relative flex flex-row justify-between items-center pl-16 overflow-hidden">
        <div className="flex flex-col gap-4 items-start">
          <div className={cn("flex gap-4 items-center", { "w-[155px]": icon })}>
            <div className="text-body truncate text-start">{text}</div>
            <DMRadioGroupIndicator
              className={cn(
                "absolute top-[17px] z-2 rounded-full bg-primary-faded",
                icon ? "right-[66px]" : "right-[16px]"
              )}
            >
              <DMIcon svg={IconCheckCircleFilled} color="primary" />
            </DMRadioGroupIndicator>
          </div>
          {description && (
            <div className="text-neutral-faded text-body-small text-start truncate w-[159px]">
              {description}
            </div>
          )}
        </div>
        {icon && (
          <div className="absolute right-[-13px]">
            <DMTwoDashedCircles
              icon={icon}
              iconColor={iconColor}
              size="medium"
            />
          </div>
        )}
        <DMImage
          image={gradient}
          className={cn(
            "absolute h-auto w-auto",
            icon ? "-right-20 -top-25" : "-right-16 -top-20"
          )}
        />
      </div>
    </DMRadioGroupPrimitiveItem>
  )
}
