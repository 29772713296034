"use client"

import * as React from "react"

import { DMIcon, DMLoader } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheckCircle, IconError } from "@supernovaio/icons"

type DMTitleDescriptionProps = {
  title: string
  type?: "bold" | "highlighted" | "faded" | "regular"
  status?: "error" | "success" | "loading"
  description?: React.ReactNode
  slotAfterTitle?: React.ReactNode
  slotBeforeTitle?: React.ReactNode
  slotBottom?: React.ReactNode
  slotLeadingImage?: React.ReactNode
}

export function DMTitleDescription(props: DMTitleDescriptionProps) {
  const {
    title,
    type = "highlighted",
    description,
    status,
    slotAfterTitle,
    slotBeforeTitle,
    slotBottom,
    slotLeadingImage,
  } = props

  const statusColor = {
    error: "text-critical",
    success: "text-positive",
    loading: "text-neutral",
  }

  const statusIcon = {
    error: <DMIcon color="critical" svg={IconError} />,
    success: <DMIcon color="positive" svg={IconCheckCircle} />,
    loading: <DMLoader />,
  }

  return (
    <div className="flex flex-row gap-12">
      {slotLeadingImage && (
        <div className="flex-shrink-0">{slotLeadingImage}</div>
      )}

      <div className="flex flex-col items-start gap-2 overflow-hidden">
        <div className="flex flex-row items-center gap-4 max-w-full">
          {slotBeforeTitle}
          <div
            className={cn(
              "text-body truncate",
              type === "bold" && "font-bold",
              type === "highlighted" && "font-semibold",
              type === "faded" && "text-neutral-faded",
              status && statusColor[status]
            )}
          >
            {title}
          </div>
          {status && statusIcon[status]}
          {slotAfterTitle}
        </div>
        <div className={cn("text-body-small text-neutral-faded font-semibold")}>
          {description}
        </div>
        {slotBottom}
      </div>
    </div>
  )
}

DMTitleDescription.displayName = "DMTitleDescription"
