import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheckCircleFilled, IconCircleDashed } from "@supernovaio/icons"

import { DMIcon } from "../DMIcon"

type Props = {
  title: string
  description: string
  isActive?: boolean
  isAccessible?: boolean
  isCompleted?: boolean
}

export function DMWizardCard({
  title,
  description,
  isActive = false,
  isAccessible = false,
  isCompleted = false,
}: Props) {
  return (
    <div
      className={cn(
        "border-neutral-faded z-2 flex flex-1 items-center justify-between gap-16 rounded border bg-white px-12 py-8",
        {
          "outline-primary outline outline-2 -outline-offset-1": isActive,
          "cursor-not-allowed": !isAccessible,
        }
      )}
    >
      <div className="flex flex-col items-start">
        <span className="text-title-5">{title}</span>
        <span
          className={cn("text-body-small text-neutral-faded font-semibold", {
            "text-disabled": !isAccessible,
          })}
        >
          {description}
        </span>
      </div>

      {isCompleted ? (
        <DMIcon svg={IconCheckCircleFilled} />
      ) : (
        <DMIcon svg={IconCircleDashed} />
      )}
    </div>
  )
}
