import React, { useCallback } from "react"

import { DMSkeleton } from "@supernovaio/dm"
import { DMLink } from "@supernovaio/dm/src/components/DMLink"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconExternal } from "@supernovaio/icons"

import type { OnClickType } from "../Actionable/Actionable.types"

export type DMNavItemCardProps = {
  disabled?: boolean
  selected?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  title: string
  description?: React.ReactNode
  descriptionLoading?: boolean
  actionTitle?: string
  isExternalAction?: boolean
  /** If provided, it prevents event propagation and default behaviour */
  onActionClick?: () => void
}

function DMNavItemCard({
  disabled = false,
  selected = false,
  startIcon,
  endIcon,
  title,
  description,
  descriptionLoading = false,
  actionTitle,
  isExternalAction = false,
  onActionClick,
}: DMNavItemCardProps) {
  const actionClickHandler = useCallback(
    (event: OnClickType) => {
      if (onActionClick) {
        event.stopPropagation()
        event.preventDefault()
        onActionClick()
      }
    },
    [onActionClick]
  )

  return (
    <div
      className={cn(
        "group flex w-full flex-col rounded p-12 ring-offset-1 focus-visible:ring-2 text-neutral transition-all",
        {
          "opacity-disabled inner-border inner-border-neutral-faded cursor-not-allowed":
            disabled,
          "hover:bg-neutral inner-border inner-border-neutral-faded":
            !disabled && !selected,
          "text-primary bg-primary-faded inner-border-primary-faded inner-border":
            selected,
        }
      )}
    >
      <div className="flex flex-col gap-8">
        <div
          className={cn(
            "flex flex-auto items-center justify-between self-stretch"
          )}
        >
          <div className="flex items-center gap-4">
            {startIcon && <div>{startIcon}</div>}
            <div
              className={cn(
                "text-body line-clamp-1 min-w-[96px] flex-grow text-start font-semibold",
                { "text-primary": selected, "opacity-disabled": disabled }
              )}
            >
              {title}
            </div>
          </div>
          {endIcon && <div>{endIcon}</div>}
        </div>
        <div className="text-neutral-faded text-body-small flex self-stretch">
          {descriptionLoading ? (
            <DMSkeleton height={64} width="85%" />
          ) : (
            description
          )}
        </div>
        {actionTitle && (
          <DMLink
            isDisabled={disabled}
            size="medium"
            variant="primary"
            onClick={actionClickHandler}
            isExternalLink={isExternalAction}
            iconEnd={isExternalAction ? IconExternal : undefined}
          >
            {actionTitle}
          </DMLink>
        )}
      </div>
    </div>
  )
}

export default DMNavItemCard
