"use client"

import * as React from "react"
import { FieldPath, FieldValues, useFormContext } from "react-hook-form"

import {
  DMDimensionInput,
  DMDimensionInputProps,
  DMField,
  DMFormErrorMessage,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@supernovaio/dm"

type Props<T extends FieldValues> = Omit<
  DMDimensionInputProps,
  "name" | "value" | "hasError"
> & {
  label?: string
  name: FieldPath<T>
}

function DMFormDimensionField<T extends FieldValues>(props: Props<T>) {
  const { label, name, ...restProps } = props

  const {
    formState: { errors },
    control,
  } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, name, ref, value } }) => (
        <FormItem>
          <DMField>
            {label && <FormLabel text={label} />}
            <FormControl>
              <DMDimensionInput
                hasError={!!errors[name]}
                {...restProps}
                ref={ref}
                name={name}
                value={value}
                onDimensionValueChange={onChange}
              />
            </FormControl>
            <DMFormErrorMessage size="medium" />
          </DMField>
        </FormItem>
      )}
    />
  )
}

export { DMFormDimensionField }
